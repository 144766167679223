import Loadable from 'react-loadable'

//标记： 在定义我们的路由对象，使用react-loadable 对路由组件进行懒加载，这是经常需要做的行为。
// 详情请参考这一篇文章：https://blog.csdn.net/China_Guanq/article/details/82194928#loadable
const loadable = (filename) => Loadable({
    loader: () => import(`../pages/${filename}`),
    loading: () => ('')
});


//路由配置对象
const routers = [
    {
        //设置默认的index.html页面以哪个页面内容为默认内容。
        path: '/',
        exact: true,
        // component: loadable('News/NewsMainPage')
        // component: loadable('News/NewsMainPage2021')
        component: loadable('News/MapMainPage')
    },
    {
        path: '/2020',
        exact: true,
        component: loadable('News/NewsMainPage')
    },

    {
        path: '/2021',
        exact: true,
        component: loadable('News/MapMainPage')
    },
    {
        path: '/2022milan',
        exact: true,
        component: loadable('News/NewsMainPage2022MiLan')
    },
    // {
    //     path: '/chinavis',
    //     exact: true,
    //     component: loadable('/News/Chinavis2022A')
    // },
];

export default routers;

