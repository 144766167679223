

import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Link, Route, Switch } from 'react-router-dom';
// import {  MemoryRouter as Router, Link, Route, Switch } from 'react-router-dom';
import './index.css';
import history from './router/history';
import routers from './router/router';
import registerServiceWorker from './registerServiceWorker';
/**
 * 4.0之后不能嵌套Route，所以需要switch选择一个.
 */
ReactDOM.render(

    <Router history={history}>
        <Switch>
            {
                routers.map((route, index) => {
                    return (
                        <Route
                            key={index}
                            path={route.path}
                            exact={route.exact}
                            component={route.component} />
                    )
                })
            }
        </Switch>
    </Router>
    , document.getElementById('root'));

//每次后缀改成任何页面都是从登录页面开始的。
// ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
registerServiceWorker();
